package com.aac.aphrodite.core.components.toast

import com.aac.aphrodite.core.contexts.toast.Toast
import js.core.jso
import mui.material.AlertColor
import mui.material.SnackbarOriginHorizontal
import kotlin.time.Duration

class ToastBuilder {

    private var _message: String? = null

    private var _severity: AlertColor? = null

    private var _horizontal: SnackbarOriginHorizontal? = null

    private var _duration: Duration? = null

    fun message(message: String) : ToastBuilder {
        _message = message
        return this
    }

    fun severity(severity: AlertColor): ToastBuilder {
        _severity = severity
        return this
    }

    fun horizontalPosition(position: SnackbarOriginHorizontal): ToastBuilder {
        _horizontal = position
        return this
    }

    fun duration(duration: Duration): ToastBuilder {
        _duration = duration
        return this
    }

    fun build(): Toast {
        return jso {
            duration = _duration
            message = _message
            position = jso {
                horizontal = _horizontal
            }
            severity = _severity
        }
    }
}