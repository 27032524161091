package com.aac.aphrodite.module.components

import com.aac.aphrodite.core.hooks.useSharedDelegate
import com.aac.aphrodite.module.Example
import kotlinx.datetime.Instant
import kotlinx.serialization.Serializable
import mui.material.Typography
import mui.material.styles.TypographyVariant
import react.*

@Serializable
data class ExampleSharedData(var lastCallDateTime: Instant)

val Shared = FC<Props> {

    var stateArray by useState(emptyArray<ExampleSharedData>())

    val delegate = useSharedDelegate<Array<ExampleSharedData>>(Example.SharedExampleKey)

    useEffect(delegate) {
        delegate.invoke()?.then {
            stateArray = it
        }
    }

    Typography {
        variant = TypographyVariant.caption
        +"Last time delegate was called for cache:"
    }
    Typography {
        variant = TypographyVariant.body1
        +stateArray.firstOrNull()?.lastCallDateTime?.toString()
    }
}