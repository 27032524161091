package com.aac.aphrodite.components.content

import com.aac.aphrodite.components.common.Navi
import com.aac.aphrodite.core.module.Module
import mui.material.Box
import mui.material.Container
import mui.system.sx
import react.FC
import react.Props
import web.cssom.Flex
import web.cssom.pct

val ContentWrapper = FC<ContentWrapperProps> { p ->

    Box {
        sx {
            width = 100.pct
            flex = Flex.minContent
        }

        Container {
            Navi {
                modules = p.modules
            }
        }
    }
}

external interface ContentWrapperProps : Props {

    var modules: List<Module<*>>
}