package com.aac.aphrodite.components.common

import com.aac.aphrodite.core.module.Module
import mui.material.Tab
import mui.material.Tabs
import mui.system.sx
import react.FC
import react.Props
import react.ReactNode
import react.router.Outlet
import react.router.useLocation
import react.router.useNavigate
import web.cssom.Color
import web.cssom.px

val Navi = FC<NaviProps> { p ->

    val allowedModules: List<Module<*>> = p.modules

    val navigate = useNavigate()

    val location = useLocation()

    if (location.pathname.startsWith("/settings/")) {

        Outlet {
            context = allowedModules
        }


    } else if (location.pathname != "/") {

        Tabs {
            sx {
                marginBottom = 15.px
            }
            value = location.pathname
            onChange = { _, newPath ->
                navigate(newPath)
            }
            for (module in allowedModules) {
                Tab {
                    sx {
                        color = Color("secondary.contrastText")
                    }
                    label = ReactNode(module.tabTitle)
                    value = module.routes.first().path
                }
            }
        }

        Outlet {
        }
    }


}

external interface NaviProps : Props {

    var modules: List<Module<*>>
}