package com.aac.aphrodite

import com.aac.aphrodite.core.hooks.useAuth
import com.aac.aphrodite.core.module.AuthModule
import com.aac.aphrodite.core.module.Module
import com.aac.aphrodite.providers.CountryProvider
import react.FC
import react.Props
import react.router.Outlet
import react.router.dom.useSearchParams
import react.router.useLocation
import react.router.useNavigate
import react.useEffect
import react.useMemo

val Work = FC<WorkProps> {

    val (tokens, setTokens) = useAuth()
    val (params) = useSearchParams()

    val token = tokens?.workToken
    val secureToken = tokens?.secureToken

    val authModule = useMemo {
        (it.modules.first { it is AuthModule } as AuthModule).apply {
            onSignedInEvent = { t, st ->
                setTokens(t, st)
            }
        }
    }

    val securedPaths = useMemo {
        it.modules.flatMap { it.securedPaths }.toSet()
    }

    val navigate = useNavigate()

    val routeLocation = useLocation()

    val currentPath = routeLocation.pathname

    //maybe to set the default path if params["backUrl"] == null from config value?
    val backUrl = params["backUrl"] ?: "/"

    useEffect(tokens, routeLocation) {
        if (authModule.signPaths.contains(routeLocation.pathname)) {
            if (currentPath == authModule.signInPath) {
                if (token != null && !token.isTokenExpired && !securedPaths.contains(backUrl)) {
                    navigate(backUrl)
                }

                if (secureToken != null && !secureToken.isTokenExpired) {
                    navigate(backUrl)
                }
            }
        } else {
            if (token == null || token.isTokenExpired) {
                navigate("${authModule.signInPath}?backUrl=${currentPath}")
            }

            if ((secureToken == null || secureToken.isTokenExpired) && securedPaths.contains(currentPath)) {
                navigate("${authModule.signInPath}?backUrl=${currentPath}")
            }
        }

    }

    if (token != null && !token.isTokenExpired && !authModule.signPaths.contains(routeLocation.pathname)) {
        CountryProvider {
            Core {
                this.modules = it.modules
            }
        }
    } else if (authModule.signPaths.contains(routeLocation.pathname)) {
        Outlet {}
    }
}

external interface WorkProps : Props {

    var modules: List<Module<*>>
}