package com.aac.aphrodite.core.contexts.toast

import mui.material.AlertColor
import mui.material.SnackbarOriginHorizontal
import mui.material.SnackbarOriginVertical
import react.PropsWithChildren
import react.createContext
import kotlin.time.Duration

val ToastContext = createContext<Triple<List<Toast>, (Toast) -> Unit, (Number) -> Unit>>()

external interface ToastProviderProps: PropsWithChildren {

    var queueSize: Int?
}

external interface Toast {

    @Suppress("INLINE_CLASS_IN_EXTERNAL_DECLARATION_WARNING")
    var duration: Duration?

    var firstRun: Double

    var key: Number?

    var message: String?

    var onClose: (() -> Unit)?

    var position: ToastPosition?

    var severity: AlertColor?

    var title: String?
}

external interface ToastPosition {

    var vertical: SnackbarOriginVertical?

    var horizontal: SnackbarOriginHorizontal?
}