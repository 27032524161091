package com.aac.aphrodite.providers

import com.aac.aphrodite.core.contexts.auth.AuthContext
import com.aac.aphrodite.core.contexts.auth.AuthContextData
import com.aac.aphrodite.core.contexts.auth.PermissionsTokenWrapper
import com.aac.aphrodite.core.contexts.auth.TokenWrapper
import com.aac.aphrodite.core.tokens.TokenHolder
import kotlinx.browser.localStorage
import react.*

val AuthProvider = FC<PropsWithChildren> {

    val workTokenKey = "workToken"

    val secureTokenKey = "secureToken"

    val (tokens, setTokens) = useState {
        wrapTokens(localStorage.getItem(workTokenKey), localStorage.getItem(secureTokenKey))
    }

    fun setTokens(workToken: String, secureToken: String) {
        localStorage.setItem(workTokenKey, workToken)
        localStorage.setItem(secureTokenKey, secureToken)
        setTokens(wrapTokens(workToken, secureToken))
    }

    fun removeTokens() {
        localStorage.removeItem(workTokenKey)
        localStorage.removeItem(secureTokenKey)
        setTokens(null)
    }

    AuthContext.Provider {
        value = AuthContextData(tokens, { t, st -> setTokens(t, st) }, { removeTokens() })
        +it.children
    }
}

private fun wrapTokens(workToken: String?, secureToken: String?) = if (workToken != null && secureToken != null) {
    TokenHolder(PermissionsTokenWrapper(workToken), TokenWrapper(secureToken))
} else null