package com.aac.aphrodite.core.contexts.auth

import com.aac.permissions.Permissions

class PermissionsTokenWrapper(token: String): TokenWrapper<PermissionsTokenPayload>(token) {

    private var parsedPermissions: Map<String, Array<String>>? = null

    val permissions: Map<String, Array<String>>
        get() {
            if (parsedPermissions == null) {
                parsedPermissions = mapOf(payload.permissions)
            }

            return parsedPermissions!!
        }

    val defaultCountry: String?
        get() = permissions.keys.firstOrNull()

    fun hasPermission(country: String, permission: Permissions) = permissions[country]?.contains(permission.code) == true || permissions[Permissions.ANY_COUNTRY]?.contains(Permissions.SUPER_ADMIN.code) == true

    private fun <T: Any> entriesOf(jsObject: dynamic): List<Pair<String, T>> =
        (js("Object.entries") as (dynamic) -> Array<Array<T?>>)
        .invoke(jsObject)
        .map { entry -> entry[0] as String to entry[1] as T }

    private fun <T: Any> mapOf(jsObject: dynamic): Map<String, T> =
        entriesOf<T>(jsObject).toMap()
}