package com.aac.aphrodite.components.toolbar

import mui.icons.material.AccountCircle
import mui.material.Box
import mui.material.IconButton
import mui.material.IconButtonColor
import mui.material.Size
import mui.system.sx
import react.FC
import react.Props
import react.dom.aria.AriaHasPopup
import react.dom.aria.ariaControls
import react.dom.aria.ariaExpanded
import react.dom.aria.ariaHasPopup
import react.useState
import web.cssom.Display
import web.dom.Element

var AccountButton = FC<Props> {

    var menuAnchor by useState<Element?>(null)

    val open = menuAnchor != null

    Box {
        sx {
            display = Display.flex
        }
        IconButton {
            size = Size.large
            color = IconButtonColor.inherit
            onClick = {
                menuAnchor = it.currentTarget
            }
            ariaControls = if (open) "user-menu" else ""
            ariaHasPopup = AriaHasPopup.`true`
            ariaExpanded = open
            AccountCircle {}
        }
    }

    UserMenu {
        id = "user-menu"
        this.open = open
        anchorEl = menuAnchor
        onClose = {
            menuAnchor = null
        }
    }
}