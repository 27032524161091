package com.aac.aphrodite.components.common

import mui.material.Box
import mui.system.sx
import react.FC
import react.Props
import react.router.useNavigate
import web.cssom.*

val Logo = FC<Props> {

    val navigate = useNavigate()

    Box {
        onClick = {
            navigate("/")
        }

        sx {
            cursor = Cursor.pointer
            width = 300.px
            height = 60.px
            backgroundImage = url("/logo.svg")
            backgroundRepeat = BackgroundRepeat.noRepeat
            backgroundPositionX = BackgroundPositionX.center
            backgroundSize = BackgroundSize.cover
        }
    }
}