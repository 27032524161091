package com.aac.aphrodite.module

import com.aac.aphrodite.core.module.Module
import com.aac.aphrodite.module.components.Main
import com.aac.permissions.Permissions
import js.core.jso
import kotlinx.datetime.Clock
import react.Props
import react.create
import react.dom.html.ReactHTML.div
import react.router.RouteObject

class Example(permissions: List<Permissions> = emptyList()): Module<Props>() {

    override val tabTitle = "Example"

    override val availableWithoutPermissions = true

    override val modulePermissions: List<Permissions> = permissions

    override val routes = listOf<RouteObject>(
        jso {
            path = "/example"

            element = Main.create()
        }
    )

    override val settingsRoute = jso<RouteObject> {
        path = "/settings/example"
        element = div.create {
            +"Here is Example settings"
        }
    }

    override val sharedDelegates = mapOf(SharedExampleKey to suspend {
        "[{ \"lastCallDateTime\": \"${Clock.System.now()}\" }]"
    })

    companion object {

        const val SharedExampleKey = "ExampleShared"
    }
}