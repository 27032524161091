package com.aac.aphrodite.components.toast

import com.aac.aphrodite.core.hooks.useToast
import js.core.jso
import mui.material.*
import mui.system.sx
import react.FC
import react.Props
import react.useMemo
import web.cssom.Margin

val ToastBar = FC<Props> {

    val (toasts) = useToast()

    val firstToast = useMemo(toasts) {
        toasts.firstOrNull()
    }

    Snackbar {
        open = firstToast != null
        autoHideDuration = null
        transitionDuration = 0
        anchorOrigin = jso {
            vertical = firstToast?.position?.vertical ?: SnackbarOriginVertical.bottom
            horizontal = firstToast?.position?.horizontal ?: SnackbarOriginHorizontal.right
        }
        sx {
            marginTop = "env(safe-area-inset-top)".unsafeCast<Margin>()
            marginBottom = "env(safe-area-inset-bottom)".unsafeCast<Margin>()
        }
        Stack {
            asDynamic().flexDirection = "column"
            asDynamic().gap = 1
            toasts.forEach {
                Toast {
                    key = it.key.toString()
                    toast = it
                }
            }
        }
    }
}