package com.aac.aphrodite.providers

import com.aac.aphrodite.core.contexts.country.CountryContext
import com.aac.aphrodite.core.hooks.useAuth
import react.FC
import react.PropsWithChildren
import react.useState

val CountryProvider = FC<PropsWithChildren> {
    
    val (tokens) = useAuth()

    val token = tokens?.workToken
    
    var country by useState(token!!.defaultCountry)
    
    CountryContext.Provider {
        value = Pair(country) {
            country = it
        }
        +it.children
    }
}