package com.aac.aphrodite.core.hooks

import com.aac.aphrodite.core.contexts.shared.SharedContext
import com.aac.aphrodite.core.contexts.shared.SharedDelegate
import kotlinx.serialization.json.Json
import react.useCallback
import react.useContext
import kotlin.js.Promise

fun useShared() = useContext(SharedContext) ?: throw Exception("useShared hook can be used only inside SharedProvider")

inline fun <reified T> useSharedDelegate(key: String): () -> Promise<T>? {
    val (getData) = useShared()

    return useCallback(key) {
        val rawData = getData.invoke(key)
        if (rawData == null) {
            console.warn("Shared data with $key wasn't found!")
            null
        }
        else {
            rawData.then {
                Json.decodeFromString(it)
            }
        }
    }
}

fun useSharedAddDelegate(key: String, delegate: SharedDelegate) {
    val (_, addDelegate) = useShared()
    addDelegate.invoke(key, delegate)
}