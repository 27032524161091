package com.aac.aphrodite

import com.aac.aphrodite.core.module.Module
import com.aac.aphrodite.providers.AuthProvider
import com.aac.aphrodite.providers.SharedProvider
import react.create
import react.dom.client.createRoot
import web.dom.document

fun run(modules: List<Module<*>>) {
    createRoot(document.createElement("div").also {
        it.style.height = "100%"
        document.body.appendChild(it)
    }).render(
        AuthProvider.create {
            SharedProvider {
                App {
                    this.modules = modules
                }
            }
        }
    )
}