package com.aac.aphrodite.core.contexts.auth

import jwt.decode.JwtPayload
import jwt.decode.jwtDecode
import kotlinx.datetime.Clock
import kotlinx.datetime.Instant

open class TokenWrapper<T: JwtPayload>(val rawToken: String) {

    private var parsedToken: T? = null

    val payload: T
        get() {
            if (parsedToken == null) {
                parsedToken = jwtDecode<T>(rawToken)
            }

            return parsedToken!!
        }
    val isTokenExpired : Boolean
        get() {
            val exp = payload.exp!!
            return Instant.fromEpochSeconds(exp.toLong()) < Clock.System.now()
        }
}