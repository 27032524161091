package com.aac.aphrodite.components.toolbar

import com.aac.aphrodite.core.hooks.useAuth
import mui.icons.material.Logout
import mui.icons.material.Settings
import mui.material.Divider
import mui.material.Menu
import mui.material.MenuItem
import react.FC
import react.Props
import react.router.useNavigate
import web.dom.Element

var UserMenu = FC<UserMenuProps> { p ->

    val navigate = useNavigate()

    val (_, _, removeTokens) = useAuth()

    Menu {
        this.id = p.id
        this.open = p.open
        this.anchorEl = {
            p.anchorEl ?: it
        }
        this.onClose = {
            p.onClose.invoke()
        }
        MenuItem {
            onClick = {
                navigate("settings/main")
                p.onClose.invoke()
            }
            Settings {}
            +"Settings"
        }
        Divider {}
        MenuItem {
            onClick = {
                removeTokens()
                p.onClose.invoke()
            }
            Logout{}
            +"Logout"
        }
    }
}

external interface UserMenuProps: Props {

    var anchorEl: Element?

    var id: String

    var open: Boolean

    var onClose: () -> Unit
}