package com.aac.aphrodite

import com.aac.aphrodite.components.settings.MainSettings
import com.aac.aphrodite.components.settings.SettingsForm
import com.aac.aphrodite.components.toast.ToastBar
import com.aac.aphrodite.core.hooks.useSharedAddDelegate
import com.aac.aphrodite.core.module.Module
import com.aac.aphrodite.providers.SharedProvider
import com.aac.aphrodite.providers.ToastProvider
import js.core.jso
import mui.material.PaletteMode
import mui.material.styles.ThemeProvider
import mui.material.styles.createTheme
import react.FC
import react.Props
import react.create
import react.router.RouteObject
import react.router.RouterProvider
import react.router.dom.createBrowserRouter
import web.cssom.rgb

val appTheme = createTheme(
    jso {
        palette = jso {
            mode = PaletteMode.dark
            primary = jso {
                main = rgb(210, 35, 70)
                contrastText = rgb(255, 255, 255, 0.75)
            }
            background = jso {
                paper = "rgb(0, 0, 0, 0.75)"
                default = "#000000"
            }
        }
        typography = jso {
            fontFamily = "Manrope"
        }
    }

)

val App = FC<AppProps> {

    it.modules.forEach {
        it.sharedDelegates?.forEach {
            useSharedAddDelegate(it.key, it.value)
        }
    }

    ThemeProvider {
        theme = appTheme
        ToastProvider {
            ToastBar {}
            RouterProvider {
                this.router = createBrowserRouter(buildRoutes(it.modules))
            }
        }
    }
}

private fun buildRoutes(modules: List<Module<*>>): Array<RouteObject> {
    val settingsRoutes = mutableListOf<RouteObject>(
        jso {
            path = "/settings/main"
            element = MainSettings.create()
        }
    )

    val childRoutes = mutableListOf<RouteObject>(
        jso {
            path = "/settings"
            element = SettingsForm.create()
            children = settingsRoutes.apply {
                addAll(modules.filter { it.settingsRoute != null }.map { it.settingsRoute!! })
            }.toTypedArray()
        }
    )

    val routes = listOf<RouteObject>(
        jso {
            path = "/"
            element = Work.create {
                this.modules = modules
            }
            children = childRoutes.apply { addAll(modules.flatMap { it.routes }) }.toTypedArray()
        }
    )
    return routes.toTypedArray()
}

external interface AppProps : Props {

    var modules: List<Module<*>>
}