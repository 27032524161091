package com.aac.aphrodite.core.module

import com.aac.aphrodite.core.contexts.shared.SharedDelegate
import com.aac.permissions.Permissions
import react.Props
import react.router.RouteObject

/**
 * This is the abstract base class for a Module in Aphrodite Core. 
 * It specifies the common attributes shared by all modules in the system. 
 *
 * @constructor An abstract class with a generic parameter T, extended from Props.
 *
 * @property tabTitle This is a non-modifiable property and needs to be overridden by each specific module class. It holds the title of the tab.
 * @property availableWithoutPermissions This property is open, so it can be overridden by the specific module class, default it's false.
 * @property modulePermissions A non-modifiable list of permissions that needs to be overridden by each specific module class. 
 * @property routes This is a non-modifiable List<RouteObject>. Must be overridden by each individual module class.
 * @property settingsRoute This property is open, so it could be overridden by individual module class if needed. By default, it is set to null.
 * @property settingsTitle This property is open, can be overridden by the specific module class. It holds the title of settings, default it's tabTitle.
 *
 * @function allowedForPermissions Function to check whether the module is permitted for the given userPermissions or if it is available without permission.
 */
abstract class Module<T: Props> {
    
    /**
     * This property is abstract and needs to be overridden by the subclass. The tabTitle is of type String and signifies 
     * the title displayed on the tab of the Module. It is an unmodifiable attribute of the Module.
     */
    
    abstract val tabTitle: String

    /**
     * Flag determining if the module is accessible without any permissions. 
     * It is declared 'open', so it can be overridden by subclasses if necessary. 
     * Default value is 'false', meaning that access is denied without explicit permissions.
     */
    open val availableWithoutPermissions = false

    abstract val modulePermissions: List<Permissions>

    abstract val routes: List<RouteObject>

    /**
     * This property is labeled 'open', meaning that subclasses can optionally override it as necessary. 
     * Its type is RouteObject?, allowing for null values.
     * The settingsRoute property is used to define a route object for accessing the settings of the module.
     * By default, it is set to null, indicating that there may not be a specific settings route for every module.
     */
    open val settingsRoute: RouteObject? = null

    open val securedPaths: List<String> = emptyList()

    open val sharedDelegates: Map<String, SharedDelegate>? = null

    /**
     * This property is declared 'open', meaning that subclasses can override it if necessary.
     * It represents the title of the settings in a specific module.
     * By default, it fetches the value from the tabTitle property.
     * Overriding subclasses can assign unique titles to the settings, as required.
     */
    
    open val settingsTitle: String
        get() = tabTitle

    fun allowedForPermissions(userPermissions: Array<String>) = availableWithoutPermissions || modulePermissions.any { userPermissions.contains(it.code) }
}