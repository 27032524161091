package com.aac.aphrodite.providers

import com.aac.aphrodite.core.contexts.shared.SharedContext
import com.aac.aphrodite.core.contexts.shared.SharedDelegate
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.promise
import kotlinx.datetime.*
import react.FC
import react.PropsWithChildren
import react.useCallback
import kotlin.js.Promise

private data class SharedWrapper(val delegate: SharedDelegate) {

    private var cache: String? = null

    private var lastCall: Instant? = null

    suspend fun getData() : String {
        val now = Clock.System.now()
        if (lastCall == null || now.minus(lastCall!!).inWholeSeconds > 30) {
            cache = delegate.invoke()
            lastCall = now
        }

        return cache!!
    }
}

val sharedScope = CoroutineScope(Dispatchers.Default)

val SharedProvider = FC<PropsWithChildren> {

    val sharedData = mutableMapOf<String, SharedWrapper>()

    val getSharedData = useCallback<(String) -> Promise<String>?> {
        val wrapper = sharedData[it]
        if (wrapper != null) {
            return@useCallback sharedScope.promise { wrapper.getData() }
        }

        return@useCallback null
    }

    val setDelegate = useCallback<(String, SharedDelegate) -> Unit> {key, delegate ->
        sharedData[key] = SharedWrapper(delegate)
    }

    SharedContext.Provider {
        value = Pair(
            getSharedData,
            setDelegate
        )
        +it.children
    }
}