package com.aac.aphrodite.core.hooks

import com.aac.aphrodite.core.clients.AnonymousClient
import com.aac.aphrodite.core.clients.TokenClient
import com.aac.aphrodite.core.contexts.auth.TokenWrapper
import react.useMemo
import react.useState

inline fun <reified T: AnonymousClient> useAnonymousClient(): T {

    val client by useState(createInstance(T::class.js))
    return client
}

inline fun <reified T: TokenClient> useTokenClient(): T? {

    val (tokens) = useAuth()

    return useMemoClient(tokens?.workToken)
}

inline fun <reified T : TokenClient> useSecureClient(): T? {

    val (tokens) = useAuth()

    return useMemoClient(tokens?.secureToken)
}

inline fun <reified T: TokenClient> useMemoClient(token: TokenWrapper<*>?) :T? = useMemo(token) {
    if (token?.isTokenExpired == false) 
        createInstance(T::class.js, token.rawToken)
    else
        null
}

fun <T : Any> createInstance(clazz: JsClass<T>, param: String = ""): T {
    if (param.isNotEmpty())
        return js("new clazz(param);") as T
    return js("new clazz();") as T
}