package com.aac.aphrodite.components.footer

import kotlinx.datetime.Clock
import kotlinx.datetime.toJSDate
import mui.material.*
import mui.material.styles.TypographyVariant
import mui.system.sx
import react.FC
import react.Props
import react.dom.html.ReactHTML.footer
import web.cssom.FlexShrink
import web.cssom.pct
import web.cssom.px
import web.cssom.rem

val Footer = FC<Props> {

    footer {

        Paper {
            sx {
                width = 100.pct
                flexShrink = 0.unsafeCast<FlexShrink>()
                paddingTop = 1.rem
                paddingBottom = 1.rem
                paddingTop = 25.px
                paddingBottom = 25.px
            }
            Container {
                maxWidth = "lg"
                Grid {
                    item = true
                    Typography {
                        variant = TypographyVariant.h5
                        +"AAC Portal"
                    }
                }
                Grid {
                    item = true
                    Typography {
                        variant = TypographyVariant.subtitle1
                        +"${Clock.System.now().toJSDate().getFullYear()} | AAC Development Team"
                    }
                }
            }
        }
    }
}
