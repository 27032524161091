package com.aac.aphrodite.components.toast

import com.aac.aphrodite.core.contexts.toast.Toast
import com.aac.aphrodite.core.hooks.useToast
import js.core.jso
import mui.material.*
import mui.system.responsive
import mui.system.sx
import react.*
import web.cssom.px
import web.timers.clearTimeout
import web.timers.setTimeout
import kotlin.js.Date
import kotlin.time.Duration.Companion.INFINITE
import kotlin.time.Duration.Companion.milliseconds

const val TIMEOUT = 300

val Toast = FC<ToastProps>{t ->

    var open by useState(true)

    val (_, _, removeToast) = useToast()

    val close = useCallback(t.toast.key, removeToast) {
        open = false
        setTimeout(TIMEOUT.milliseconds) {
            removeToast(t.toast.key!!)
        }
    }

    useEffect(close) {
        if (t.toast.duration != INFINITE) {
            val timeOut = setTimeout(t.toast.duration!! - (Date.now() - t.toast.firstRun).milliseconds) {
                close()
            }
            cleanup {
                clearTimeout(timeOut)
            }
        }
    }

    Grow {
        `in` = open
        timeout = TIMEOUT
        Alert {
            key = t.toast.key.toString()
            severity = t.toast.severity ?: AlertColor.info
            onClose = {
                t.toast.onClose?.invoke()
                close()
            }
            variant = AlertVariant.filled
            sx {
                minWidth = 280.px
                width = jso {
                    asDynamic().xs = responsive(1)
                    asDynamic().md = "auto"
                }
                marginBottom = 1.px
            }
            if (t.toast.title != null) {
                AlertTitle {
                    +t.toast.title
                }
            }
            +t.toast.message
        }
    }
}

external interface ToastProps : Props {

    var toast: Toast
}