package com.aac.aphrodite.components.toolbar

import com.aac.aphrodite.components.common.Logo
import com.aac.aphrodite.core.hooks.useAuth
import com.aac.aphrodite.core.hooks.useCountry
import mui.material.*
import mui.material.styles.Theme
import mui.material.styles.useTheme
import mui.system.sx
import react.FC
import react.Props
import web.cssom.number
import web.cssom.px


val MainBar = FC<Props> {
    val (country, setCountry) = useCountry()

    val (tokens) = useAuth()

    val token = tokens?.workToken

    val theme = useTheme<Theme>()


    AppBar {
        position = AppBarPosition.static

        sx {
            paddingTop = 15.px
            paddingLeft = 60.px
            paddingRight = 60.px
            paddingBottom = 15.px
        }

        Toolbar {
            Box {
                sx {
                    flexGrow = number(1.0)
                }
                Logo {}
            }

            Box{
                FormControl {
                    variant = FormControlVariant.standard
                    fullWidth = true

                    InputLabel {
                        color = FormLabelColor.secondary

                        id = "country-label"
                        +"Country"
                    }

                    Select {
                        sx {
                            color = theme.palette.primary.light
                            borderBottomColor = theme.palette.primary.light
                        }

                        labelId = "country-label"
                        id = "country-selector"
                        value = country ?: ""
                        onChange = { event, _ ->
                            setCountry(event.target.value)
                        }
                        for (c in token!!.permissions.keys) {
                            MenuItem {
                                value = c
                                +c
                            }
                        }
                    }
                }
            }
            AccountButton {}
        }
    }
}