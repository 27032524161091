package com.aac.aphrodite.providers

import com.aac.aphrodite.core.contexts.toast.Toast
import com.aac.aphrodite.core.contexts.toast.ToastContext
import com.aac.aphrodite.core.contexts.toast.ToastProviderProps
import react.FC
import react.useState
import kotlin.js.Date
import kotlin.time.Duration.Companion.seconds

const val defaultQueueSize = 3

val defaultToastDuration = 6.seconds

val ToastProvider = FC<ToastProviderProps> { pp ->

    val (toastQueue, setToastQueue) = useState<List<Toast>>(listOf())

    val addToast: (Toast) -> Unit = {

        val key = it.key ?: Date.now()

        if (toastQueue.count { t -> t.key == key } != 1) {
            val keep = (pp.queueSize ?: defaultQueueSize) - 1
            val newQueue = toastQueue.take(keep).toMutableList()
            newQueue.add(0, it.apply { this.key = key; this.firstRun = Date.now(); this.duration = this.duration ?: defaultToastDuration })
            setToastQueue(newQueue)
        }
    }

    val removeToast: (Number) -> Unit = {
        val newQueue = toastQueue.filter { t -> t.key != it }
        setToastQueue(newQueue)
    }

    ToastContext.Provider {
        value = Triple(toastQueue, addToast, removeToast)
        +pp.children
    }
}