package com.aac.aphrodite

import com.aac.aphrodite.components.content.ContentWrapper
import com.aac.aphrodite.components.footer.Footer
import com.aac.aphrodite.components.toolbar.MainBar
import com.aac.aphrodite.core.hooks.useAuth
import com.aac.aphrodite.core.hooks.useCountry
import com.aac.aphrodite.core.module.Module
import mui.material.Box
import mui.system.sx
import react.FC
import react.Props
import react.router.useLocation
import react.router.useNavigate
import react.useEffect
import react.useMemo
import web.cssom.Display
import web.cssom.FlexDirection
import web.cssom.pct

val Core = FC<CoreProps> { p ->

    val (country) = useCountry()

    val (tokens) = useAuth()

    val token = tokens?.workToken

    val allowedModules = useMemo(country, token) {
        p.modules.filter { it.allowedForPermissions(token?.permissions?.get(country) ?: emptyArray()) }
    }

    val navigate = useNavigate()

    val location = useLocation()

    useEffect(allowedModules, location) {
        if (!location.pathname.startsWith("/settings/") && allowedModules.isNotEmpty()) {
            if (location.pathname == "/" || allowedModules.all { it.routes.count { it.path == location.pathname } == 0 }) {
                navigate(allowedModules.first().routes.first().path!!)
            }
        }
    }
    Box {
        sx {
            display = Display.flex
            flexDirection = FlexDirection.column
            minHeight = 100.pct
        }

        MainBar {}

        ContentWrapper {
            modules = allowedModules
        }

        Footer {}
    }

}

external interface CoreProps : Props {
    
    var modules: List<Module<*>>
}