package com.aac.aphrodite.components.settings

import com.aac.aphrodite.core.module.Module
import mui.material.*
import mui.system.sx
import react.FC
import react.Props
import react.ReactNode
import react.dom.aria.AriaRole
import react.router.Outlet
import react.router.useLocation
import react.router.useNavigate
import react.router.useOutletContext
import react.useMemo
import web.cssom.*

data class TabData(val label: ReactNode, val value: String)

val SettingsForm = FC<Props> {

    val location = useLocation()

    val navigate = useNavigate()

    val context = useOutletContext<List<Module<*>>?>()

    val tabs = useMemo(context) {
        (context ?: emptyList()).filter { it.settingsRoute != null }
            .map { m ->
                TabData(ReactNode(m.settingsTitle), m.settingsRoute!!.path!!)
            }.toMutableList().apply {
                add(0, TabData(ReactNode("Main"), "/settings/main"))
            }
    }

    Box {
        sx {
            flexGrow = number(1.0)
            display = Display.flex
        }
        Tabs {
            sx {
                borderRight = Border(1.px, LineStyle.solid, Color("divider"))
            }
            orientation = Orientation.vertical
            variant = TabsVariant.scrollable
            value = location.pathname
            onChange = { _, newPath ->
                navigate(newPath)
            }
            for (tab in tabs) {
                Tab {
                    sx {
                        color = Color("secondary.contrastText")
                    }
                    label = tab.label
                    value = tab.value
                }
            }
        }

        Box {
            role = AriaRole.tabpanel
            Box {
                sx {
                    padding = 3.px
                }
                Outlet {}
            }
        }
    }
}