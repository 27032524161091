package com.aac.aphrodite.module.components

import mui.material.Typography
import mui.material.styles.TypographyVariant
import mui.system.sx
import react.FC
import react.Fragment
import react.Props
import web.cssom.TextTransform

val Main = FC<Props> {

    Fragment {
        Typography {
            sx {
                textTransform = TextTransform.uppercase
            }
            variant = TypographyVariant.h6

            +"I'm Example Module!"
        }

        Shared {
        }
    }
}