package com.aac.aphrodite.core.module

import react.Props

abstract class AuthModule<T: Props> : Module<T>() {

    abstract val signInPath: String

    abstract val signPaths: List<String>

    var onSignedInEvent: ((String, String) -> Unit)? = null
}